.Dashboard-fee-info {
  text-align: center;
  line-height: 2.325rem;
}

.Dashboard-note {
  text-align: center;
}

.Dashboard-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 3.1rem;
  grid-gap: 1.5rem;
}

.Dashboard-token-card .Dashboard-token-title-options {
  opacity: 0.5;
  filter: sepia(100%) hue-rotate(190deg);
}

.Dashboard-token-card:hover .Dashboard-token-title-options {
  opacity: 1;
  filter: none;
}

.Dashboard-token-title {
  display: grid;
  grid-template-columns: auto auto;
}

.Dashboard-token-title-options {
  text-align: right;
}

.Dashboard-token-title img {
  cursor: pointer;
  height: 18.5rem;
  margin-left: 0.465rem;
  opacity: 0.9;
}

.Dashboard-token-title img:hover {
  opacity: 1;
}

.Dashboard-fees {
  padding-bottom: 4.65rem;
}

@media (max-width: 700px) {
  .Dashboard-list {
    grid-template-columns: 1fr;
  }
}
