.leaderboard-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .leaderboard-table th,
  .leaderboard-table td {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
  }
  
  /* .leaderboard-table tr:nth-child(even) {
    background-color: #f2f2f2;
  } */